import Vue from 'vue'
import numeral from 'numeral'
import moment from 'moment'
import { chunk, isEmpty, size, sortBy, isBoolean, find } from 'lodash'

import {
  DATE_FORMAT,
  TIME_FORMAT,
  WITHIN_7_BUSINESS_DAYS,
  PROMOTION_TYPES,
  SHORT_PROMOTION_TYPES,
  PROMOTION_TYPE_HELP_TEXTS,
  PROMOTION_ACTIONS,
  PROMOTION_ACTIONS_TYPES, ELEMENT_MONTH_VALUE_FORMAT
} from '@/settings'

const centsFormat = (val, hasPrefix = true, trailingZeroes = true) => {
  if (typeof val === 'undefined') return ''
  const usd = numeral(val).value() / 100
  const format = trailingZeroes
    ? '0,0.00'
    : '0,0[.]00'
  const prefix = hasPrefix ? '$' : ''
  return numeral(usd).format(prefix + format)
}

export default ({ app, store, router, $moment }, inject) => {
  Vue.filter('abs', val => {
    if (typeof val === 'undefined') return val
    return Math.abs(numeral(val).value())
  })

  Vue.filter('notNull', val => {
    return val || ''
  })

  Vue.filter('boolean', (val, fallback = 'N/A') => {
    if (!isBoolean(val)) return fallback
    return (val ? 'Yes' : 'No')
  })

  Vue.filter('percentage', val => {
    if (typeof val === 'undefined') return 'N/A'
    if (val === null) return ''
    const result = numeral(val).multiply(100)
    return Number.isInteger(result.value()) ? `${result.value()}%` : `${result.format('0.00')}%`
  })

  Vue.filter('week', (val) => {
    if (!val) return '-'
    return `${$moment(val).format(DATE_FORMAT)} - ${$moment(val).add(6, 'd').format(DATE_FORMAT)}`
  })

  Vue.filter('isoWeek', (val) => {
    if (!val) return ''
    return $moment(val).isoWeek()
  })

  Vue.filter('date', (val, format = DATE_FORMAT, fallback = '-') => {
    if (!val) return fallback
    return $moment(val).format(format)
  })

  Vue.filter('dateRange', (start, end, stricted = true) => {
    if ((!start && !end) || ((!start || !end) && stricted)) return ''

    const tStart = moment(start)
    const tEnd = moment(end)

    if (!tEnd.isValid() || tStart.isSame(tEnd)) return `From ${tStart.format(DATE_FORMAT)}`
    if (!tStart.isValid()) return `Until ${tEnd.format(DATE_FORMAT)}`

    return `${tStart.format(DATE_FORMAT)} - ${tEnd.format(DATE_FORMAT)}`
  })

  Vue.filter('timeRange', (start, end) => {
    if ((!start && !end) || ((!start || !end))) return '-'

    // remove timezone
    const tStart = moment(start.substr(0, 23))
    const tEnd = moment(end.substr(0, 23))

    if (!tEnd.isValid() || tStart.isSame(tEnd)) return `From ${tStart.format(TIME_FORMAT)}`
    if (!tStart.isValid()) return `Until ${tEnd.format(TIME_FORMAT)}`

    return `${tStart.format(TIME_FORMAT)} - ${tEnd.format(TIME_FORMAT)}`
  })

  Vue.filter('year', (val) => {
    if (!val) return ''
    return $moment(val).format('Y')
  })

  const capitalizeFirstLetter = (val) => {
    if (!val) return 'N/A'
    return val.charAt(0).toUpperCase() + val.slice(1)
  }
  Vue.filter('capitalizeFirstLetter', capitalizeFirstLetter)

  Vue.filter('dateWithoutTimezone', (val, format = DATE_FORMAT) => {
    if (!val) return ''
    return $moment(val.substr(0, 23)).format(format)
  })

  Vue.filter('time', (val, format = TIME_FORMAT) => {
    if (!val) return ''
    return $moment(val).format(format)
  })

  Vue.filter('timeWithoutTimezone', (val, format = TIME_FORMAT, emptyFormat = '') => {
    if (!val) return emptyFormat
    return $moment(val.substr(0, 23)).format(format)
  })

  Vue.filter('datetime', (val, format = `${DATE_FORMAT} ${TIME_FORMAT}`) => {
    if (!val) return ''
    return $moment(val).format(format)
  })

  Vue.filter('breaker', (val, symbol = '/') => {
    if (!val) return ''
    return val.split(symbol).join(` ${symbol} `)
  })

  Vue.filter('join', (val, symbol = ', ') => {
    if (!val || !Array.isArray(val)) return ''
    return val.join(symbol)
  })

  const shortWeekday = (val, delimiter = ', ') => {
    const converter = code => {
      return {
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat',
        sunday: 'Sun'
      }[code] || 'N/A'
    }
    if (Array.isArray(val)) return val.map(converter).join(delimiter)
    return converter(val)
  }
  Vue.filter('shortWeekday', shortWeekday)

  Vue.filter('address', val => {
    if (!val) return ''
    const res = [
      val.attn,
      val.street1,
      val.street2,
      val.city,
      val.address_state_id
      ? Vue.filter('addressState')(val.address_state_id)
      : val.state ? val.state : '',
      val.zip,
      val.country
    ]
    return res.filter(i => !!i).join(', ')
  })

  Vue.filter('addressState', (val, field = 'name', defaultValue = '-') => {
    const res = store.getters['master/addressStates']
      .find(i => i.id === val)
    return res && res[field] ? res[field] : defaultValue
  })

  Vue.filter('country', (val, field = 'name') => {
    const res = store.getters['master/countries']
      .find(i => i.id === val)
    return res && res[field] ? res[field] : '-'
  })

  Vue.filter('dateToTime', val => {
    if (!val) return null
    if (typeof val === 'string') {
      return $moment(val.substr(0, 23)).format('HH:mm')
    }
    return null
  })

  Vue.filter('number', (val, format = '0,0') => {
    if (val === undefined) return '-'
    return numeral(val).format(format)
  })

  Vue.filter('int', val => {
    if (typeof val === 'undefined') return '-'
    return numeral(val).format('0,0')
  })

  Vue.filter('float', val => {
    if (typeof val === 'undefined') return '-'
    return numeral(val).format('0,0[.]0[0]')
  })

  Vue.filter('cents', centsFormat)

  Vue.filter('intCents', (val, hasPrefix = true) => {
    if (typeof val === 'undefined') return ''

    const usd = numeral(val).value() / 100
    const format = hasPrefix ? '$0,0' : '0,0'

    return numeral(usd).format(format)
  })

  Vue.filter('financialCents', (val, negative = true, hideZero = true) => {
    if (typeof val === 'undefined') return ''
    if (hideZero && val === 0) return ''
    const usd = numeral(val).value() / 100
    const string = numeral(Math.abs(usd)).format('$0,0.00')

    return negative
      ? (usd > 0 ? `(${string})` : string)
      : (usd < 0 ? `(${string})` : string)
  })

  Vue.filter('usd', (val, placeholder = '') => {
    if (typeof val === 'undefined') return placeholder
    return numeral(val).format('$0,0.00')
  })

  Vue.filter('intUsd', (val, placeholder = '') => {
    if (typeof val === 'undefined') return placeholder
    return numeral(val).format('$0,0')
  })

  Vue.filter('fileSize', val => {
    if (typeof val === 'undefined') return '–'
    if (val > 1000000) return numeral(val).divide(1000000).format('0,0[.]0') + 'MB'
    if (val > 1000) return numeral(val).divide(1000).format('0,0[.]0') + 'KB'
    return numeral(val).format('0,0[.]0') + 'B'
  })

  Vue.filter('barcodeType', (id, field) => {
    const res = store.getters['master/barcodeTypeById'](id)

    if (!res) return null

    return field ? res[field] : res
  })

  Vue.filter('upc', upc => {
    if (!upc) return null
    if (upc.length !== 12) return upc

    const splitUPC = upc.match(/(.{1,11})/g)
    return `${splitUPC[0]} ${splitUPC[1]}`
  })

  Vue.filter('ean', ean => {
    if (!ean) return null
    if (ean.length !== 13) return ean

    const splitEAN = ean.match(/(.{1,12})/g)
    return `${splitEAN[0]} ${splitEAN[1]}`
  })

  Vue.filter('master', (id, type, prop = 'name', fallback = null) => {
    const data = store.getters[`master/${type}`] || []
    const res = data.find(i => i.id === id)
    return res ? res[prop] || fallback : fallback
  })

  Vue.filter('region', (id, field = 'name') => {
    const res = store.getters['master/getRegionById'](id)
    if (!res) return null
    return res[field] || null
  })

  Vue.filter('productVariantAvailability', (val, prop = 'name', fallback = '') => {
    const res = store.getters['master/productVariantAvailabilityById'](val)
    return res ? res[prop] || fallback : fallback
  })

  Vue.filter('margin', val => {
    if (!val) return ''
    return `${val}%`
  })

  const temperature = (val, suffix = ' F') => {
    if (typeof val === 'undefined' || val === null) return ''
    return numeral(val).format('0,0.0') + suffix
  }
  Vue.filter('temperature', temperature)
  Vue.filter('temperatureRange', (min, max, suffix = ' F') => {
    const hasMin = typeof min !== 'undefined' && min !== null
    const hasMax = typeof max !== 'undefined' && max !== null
    if (hasMin && hasMax) return temperature(min, suffix) + ' - ' + temperature(max, suffix)
    if (hasMin) return 'Min: ' + temperature(min, suffix)
    if (hasMax) return 'Max: ' + temperature(max, suffix)
    return ''
  })

  Vue.filter('shelfLife', (days, condition) => {
    const hasDays = typeof days !== 'undefined' && days !== null
    const hasCondition = typeof condition !== 'undefined' && condition !== null
    if (hasDays && hasCondition) return `${days} ${days > 1 ? 'days' : 'day'} - ${condition}`
    else {
      if (hasDays) return `${days} ${days > 1 ? 'days' : 'day'}`
      if (hasCondition) return condition
      return '-'
    }
  })

  Vue.filter('shelfLifeStore', (id, prop = 'name', fallback = '') => {
    const res = store.getters['master/getStroreShelfLifeById'](id, prop)

    return res || fallback
  })

  Vue.filter('productTag', (val, prop = 'name', fallback = val) => {
    const res = store.getters['master/productTagById'](val)
    return res ? res[prop] || fallback : fallback
  })

  Vue.filter('productSize', (volume, unit) => {
    const hasVolume = typeof volume !== 'undefined' && volume !== null
    const hasUnit = typeof unit !== 'undefined' && unit !== null
    if (hasVolume && hasUnit) return `${volume} ${unit}`
    return ''
  })

  Vue.filter('productCategory', (val, prop = 'name', fallback = val) => {
    const res = store.getters['master/productCategoryById'](val)
    return res ? res[prop] || fallback : fallback
  })

  Vue.filter('productType', (val, prop = 'name', fallback = val) => {
    const res = store.getters['master/productTypeById'](val)
    return res ? res[prop] || fallback : fallback
  })

  Vue.filter('productQuality', (val, prop = 'name', fallback = val) => {
    const res = store.getters['master/productQualityById'](val)
    return res ? res[prop] || fallback : fallback
  })

  Vue.filter('packageSize', (val, prop = 'name', fallback = val) => {
    const res = store.getters['master/packageSizeById'](val)
    return res ? res[prop] || fallback : fallback
  })

  // Vue.filter('shelfCondition', (condition) => {
  //   const hasCondition = typeof condition !== 'undefined' && condition !== null && condition !== 'null'
  //   if (hasCondition) return condition.toLowerCase().includes('refrigerated') || condition.toLowerCase().includes('frozen') ? <strong className="bold text-uppercase">{condition}</strong> : condition
  //   return '-'
  // })

  Vue.filter('cap', val => {
    if (!val || typeof val !== 'string') return 'N/A'
    return val.replace(/[_-]/, ' ').replace(/^\w/, c => c.toUpperCase())
  })

  const calculateFixRateDiscountPrice = (price, discountValue, units) => {
    return centsFormat(numeral((price - discountValue) / units).value())
  }
  const calculatePercentageDiscountPrice = (price, discountValue, units) => {
    return centsFormat(numeral(price * (1 - discountValue) / units).value())
  }
  Vue.filter('promotionPrice', (price, promotion = null, units = 1) => {
    if (!promotion || isEmpty(promotion.promotion_actions)) return centsFormat(price)
    const _price = numeral(price).value()

    // For stack promotions
    const promoActionType = promotion?.promo_action_type || promotion?.promo_action_type_str
    if (promoActionType === PROMOTION_ACTIONS_TYPES.STACKED) {
      const sortedPromotionActions = sortBy(promotion.promotion_actions, 'discount_value')

      const highestPromo = sortedPromotionActions[sortedPromotionActions.length - 1]
      const computePrice = {
        [PROMOTION_ACTIONS.FIX_RATE_ADJUSTMENT]: calculateFixRateDiscountPrice,
        [PROMOTION_ACTIONS.PERCENTAGE_ADJUSTMENT]: calculatePercentageDiscountPrice
      }[highestPromo.type] || (() => '')

      const lowestPrice = computePrice(_price, highestPromo.discount_value, units)

      if (size(promotion.promotion_actions) === 1) {
        return `${lowestPrice}`
      }
      const lowestPromo = sortedPromotionActions[0]
      const highestPrice = computePrice(_price, lowestPromo.discount_value, units)

      return `${lowestPrice} ~ ${highestPrice}`
    }

    // For normal promotion
    const _discount = numeral(promotion.promotion_actions[0].discount_value).value()
    switch (promotion.promotion_actions[0].discount_type) {
      case 'Percentage':
        return calculatePercentageDiscountPrice(_price, _discount, units)
      case 'Fix rate':
        return calculateFixRateDiscountPrice(_price, _discount, units)
      default:
        return centsFormat(price)
    }
  })

  Vue.filter('priceRange', (prices) => {
    const [min, max] = prices

    if (!max && !min) return ''
    if (!max) return centsFormat(min)

    return `${centsFormat(min)} ~ ${centsFormat(max)}`
  })

  Vue.filter('promotionType', (promotionType) => {
    switch (promotionType) {
      case PROMOTION_TYPES.TPR:
      case SHORT_PROMOTION_TYPES.TPR:
        return 'TPR'
      case PROMOTION_TYPES.BUY_IN:
      case SHORT_PROMOTION_TYPES.BUY_IN:
        return 'Buy-in'
      case PROMOTION_TYPES.SHORT_DATE:
      case SHORT_PROMOTION_TYPES.SHORT_DATE:
        return 'Short-dated'
      default:
        return ''
    }
  })
  Vue.filter('promotionTypeHelpText', (promotionType) => {
    switch (promotionType) {
      case PROMOTION_TYPES.TPR:
      case SHORT_PROMOTION_TYPES.TPR:
        return PROMOTION_TYPE_HELP_TEXTS.TPR
      default:
        return null
    }
  })

  const formatFixRateDiscountValue = (discountValue, decimalPoint, negativeNumber) => {
    if (!discountValue) discountValue = 0
    const _discountValue = numeral(discountValue).divide(100)
    if (!negativeNumber) {
      return decimalPoint ? `$${_discountValue.format('0.00')}` : `$${_discountValue.format('0')}`
    }
    return decimalPoint ? `-$${_discountValue.format('0.00')}` : `-$${_discountValue.format('0')}`
  }
  const formatPercentageDiscountValue = (discountValue, decimalPoint, negativeNumber) => {
    if (!discountValue) discountValue = 0
    const _discountValue = numeral(discountValue).multiply(100)
    return (!Number.isInteger(_discountValue.value()) && decimalPoint)
      ? `${_discountValue.format('0.00')}%`
      : `${_discountValue.format('0')}%`
  }
  Vue.filter('promotionDiscount', (promotions, decimalPoint = false, negativeNumber = false) => {
    const promotion = Array.isArray(promotions) ? promotions[0] : promotions

    // For stack promotions
    const promoActionType = promotion?.promo_action_type || promotion?.promo_action_type_str
    if (promoActionType === PROMOTION_ACTIONS_TYPES.STACKED) {
      const sortedPromotionActions = sortBy(promotion.promotion_actions, 'discount_value')

      const lowestPromo = sortedPromotionActions[0]
      const formatDiscountValue = {
        [PROMOTION_ACTIONS.FIX_RATE_ADJUSTMENT]: formatFixRateDiscountValue,
        [PROMOTION_ACTIONS.PERCENTAGE_ADJUSTMENT]: formatPercentageDiscountValue
      }[lowestPromo.type] || (() => '')
      const lowestDiscount = formatDiscountValue(lowestPromo.discount_value, decimalPoint, negativeNumber)

      if (size(promotion.promotion_actions) === 1) {
        return `${lowestDiscount}`
      }

      const highestPromo = sortedPromotionActions[sortedPromotionActions.length - 1]
      const highestDiscount = formatDiscountValue(highestPromo.discount_value, decimalPoint, negativeNumber)

      return `${lowestDiscount} ~ ${highestDiscount}`
    }

    // For normal promotion
    switch (promotion.promotion_actions[0].discount_type) {
      case 'Percentage':
        return formatPercentageDiscountValue(promotion.promotion_actions[0].discount_value, decimalPoint)
      case 'Fix rate':
        return formatFixRateDiscountValue(promotion.promotion_actions[0].discount_value, decimalPoint, negativeNumber)
      default:
        return ''
    }
  })

  Vue.filter('brandTierCommission', (id, field = 'name') => {
    const res = store.getters['master/brandTierCommissions']
      .find(i => i.id === id)
    if (!res) return null
    return res[field] || null
  })

  Vue.filter('fulfillmentState', (state) => {
    return state ? 'Fulfilled' : 'Not fulfilled'
  })

  Vue.filter('stateFee', (id, field = 'name') => {
    const res = store.getters['master/stateFees']
      .find(i => i.id === id)
    if (!res) return null
    return res[field] || null
  })

  Vue.filter('storeType', (val) => {
    const type = store.getters['master/storeTypes']
      .find(i => i.id === val)
    if (!type) return null
    return type.name
  })

  Vue.filter('warehouse', (val, key = null) => {
    const res = store.getters['master/getWarehouseById'](val, key)

    return res || ''
  })

  Vue.filter('deliveryTimezone', (timezone) => {
    if (!timezone) return ''

    switch (timezone.toLowerCase()) {
      case 'eastern time (us & canada)':
        return 'EST'
      case 'pacific time (us & canada)':
        return 'PST'
      case 'central time (us & canada)':
        return 'CST'
      case 'mountain time (us & canada)':
        return 'MDT'
      default:
        return timezone
    }
  })

  Vue.filter('shippoParcelSize', (parcels) => {
    if (!parcels) return
    return `${Number.parseFloat(parcels[0].length).toFixed(2)}
    x ${Number.parseFloat(parcels[0].width).toFixed(2)}
    x ${Number.parseFloat(parcels[0].height).toFixed(2)}${parcels[0].distance_unit}`
  })

  Vue.filter('shippoParcelWeight', (parcels) => {
    if (!parcels) return
    return `${Number.parseFloat(parcels[0].weight)}${parcels[0].mass_unit}`
  })

  Vue.filter('paymentType', (val) => {
    if (val === 'bank_or_cc_payment') {
      return 'Payment via credit card or bank account'
    }
    if (val === 'invoice_payment') {
      return 'By invoice'
    }
    return ''
  })

  Vue.filter('day', (val) => {
    if (!val) return ''
    return numeral(val).value() > 1 ? `${val} days` : `${val} day`
  })

  Vue.filter('receivingWeekDays', (receivingWeekDays) => {
    if (!Array.isArray(receivingWeekDays) ||
      !receivingWeekDays.length)
      return null
    const withIn7BusinessDays = receivingWeekDays.find(i => i === WITHIN_7_BUSINESS_DAYS)
    if (withIn7BusinessDays) {
      return capitalizeFirstLetter(withIn7BusinessDays)
    }
    return `Every ${shortWeekday(receivingWeekDays)}`
  })

  Vue.filter('externalLink', (url) => {
    if (!url || typeof url !== 'string') return ''
    if (url.startsWith('https://') || url.startsWith('http://')) return url
    return `https://${url}`
  })

  Vue.filter('blurryPrice', (number) => {
    if (typeof number !== 'number') return '$99.00'
    return centsFormat(number.toString().slice(-4))
  })

  Vue.filter('chartTooltip', (label, value, size = 8) => {
    const chunks = chunk(label.split(' '), size)
    if (Array.isArray(chunks?.[chunks.length - 1]))
      chunks[chunks.length - 1].push(`: ${value}`)
    return chunks.map(a => a.join(' '))
  })

  Vue.filter('minsRead', body => {
    const mins = Math.round((body?.length) / 800 + 1)
    return `${mins} ${mins > 1 ? 'mins' : 'min'} read`
  })

  Vue.filter('fromNow', val => {
    return val ? $moment(val).fromNow() : ''
  })

  Vue.filter('viewBy', (val, unit = 'month') => {
    if (!val) return '-'
    if (unit === 'year') return $moment(val).format('yyyy')
    if (unit === 'quarter') return 'Q' + $moment(val).format('Q - yyyy')

    return $moment(val).format(ELEMENT_MONTH_VALUE_FORMAT)
  })

  Vue.filter('regionWarehouse', val => {
    if (!val) return 'N/A'
    const item = find(store.getters['master/warehouses'], { id: val })
    if (!item) return val
    return item.name
  })

  Vue.filter('diff', (s, e, unit = 'days') => {
    if (!s || !e) return '-'

    const start = $moment(s)
    const end = $moment(e)

    return start.diff(end, unit)
  })

  Vue.filter('brandAddress', brand => {
    if (!brand) return ''

    const { address, city, address_state_name, address_state_id, country_name } = brand
    const state = address_state_name || Vue.filter('addressState')(address_state_id, 'name', '')

    /** undefined, null, ''...vv because Back-end returns them */
    if ([undefined, null, '', 'U.S', 'United States'].includes(country_name)) {
      if (address) return address
      return city ? `${city}, ${state}` : state
    }

    return city ? `${city}, ${country_name}` : country_name
  })
  Vue.filter('phone', (phone, defaultVal = '-') => {
    if (!phone) return defaultVal

    const cleaned = String(phone).replace(/\D+/g, '')

    if (cleaned.length !== 10) return phone

    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  })
  Vue.filter('dimension', ({ pallet_length, pallet_width, pallet_height }, defaultVal = '-') => {
    if (!pallet_length || !pallet_width || !pallet_height) return defaultVal

    return `${pallet_length}" x ${pallet_width}" x ${pallet_height}"`
  })
}
